import { graphql, useStaticQuery } from 'gatsby'
//import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .padded-section {
    p {
      + p {
        margin-top: 8px;
      }

      + div {
        margin-top: 22px;
      }
    }
  }

  .gatsby-image-wrapper {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }
`

const Parties = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEventParty(sort: { fields: [order], order: [ASC] }, filter: { eventType: { eq: "Virtual Event" } }) {
        edges {
          node {
            id
            title
            image {
              fluid(
                sizes: "(max-width: 767px) calc(100vw - 15px - 10px - 10px - 15px), (max-width: 1023px) calc(50vw - 15px - 12px), (max-width: 1069px) calc(33vw - 15px - 12px), 365px"
                quality: 75
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            whoDetails
            whereDetails
            whenDetails
            whatDetails {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            attire
          }
        }
      }
    }
  `)

  const parties = data.allContentfulEventParty.edges

  return (
    <Wrapper className="wrap">
      {parties.map(({ node: party }, i) => (
        <section key={party.id} className="padded-section">
          {i === 0 && (
            <h3 className="headline--reunion center-align">Parties</h3>
          )}
          <p
            className="title"
            style={{ maxWidth: `${party.title.length > 40 ? '540px' : null}` }}
          >
            <span>{party.title}</span>
          </p>
          <p>
            <span className="semi-bold-weight">Who:</span>
            {` ${party.whoDetails}`}
          </p>
          <p>
            <span className="semi-bold-weight">Where:</span>
            {` ${party.whereDetails}`}
          </p>
          <p>
            <span className="semi-bold-weight">When:</span>
            {` ${party.whenDetails}`}
          </p>
          {/*
          <p>
            <span className="semi-bold-weight">What:</span>
            {` ${party.whatDetails.childMarkdownRemark.rawMarkdownBody}`}
          </p>
          <p>
            <span className="semi-bold-weight">Attire:</span>
            {` ${party.attire}`}
          </p>
          {party.whoDetails.includes('Club*') && (<p>
            *See Artist Recognition tab for more details
          </p>
          )}
          <Img fluid={party.image.fluid} />
          */}
        </section>
      ))}
    </Wrapper>
  )
}

export default Parties
